import { CSSProperties, ReactNode, Ref, forwardRef } from 'react';
import clsx from 'clsx';
const LINK_COLORS = {
  blue: '[&_a_span]:text-bc-blue hover:[&_a_span]:text-bc-black',
  green: '[&_a_span]:text-bc-green hover:[&_a_span]:text-white'
};
const LIST_MARKER_COLORS = {
  blue: 'var(--bc-blue)',
  black: 'var(--bc-black)',
  white: 'var(--white)'
};
type Props = {
  linkColor: 'blue' | 'green';
  listMarkerColor: 'blue' | 'black' | 'white';
  width?: string;
  margin?: string;
  text?: ReactNode;
  id?: string;
};
export const Text = forwardRef(function Text({
  id,
  linkColor,
  listMarkerColor,
  text,
  width,
  margin
}: Props, ref: Ref<HTMLDivElement>) {
  return <div id={id} ref={ref} className={clsx('custom-text', LINK_COLORS[linkColor], width, margin)} style={({
    '--list-marker-color': LIST_MARKER_COLORS[listMarkerColor]
  } as CSSProperties)}>
			{text}
		</div>;
});